import Body from "../layout/Body/Body";
import ContactForm from "../UI/ContactForm/ContactForm";
import {HOME_CONTENT} from '../../content/home-content.js';
import {PRODUCTS_CONTENT} from '../../content/products-content.js';
import styles from './Home.module.css';
import { Link } from "react-router-dom";
const Home = ()=>{
    const { firstBlock,  secondBlock} = HOME_CONTENT;
    const topLoad = () =>{
        window.scrollTo(0, 0);
    }
    return(
        <>
        <Body>
            <div className={styles.home}>
                <div>
                    {PRODUCTS_CONTENT.map((img) =>{
                        return(
                            <img src={img.img} alt={img.alt} key={img.id}/>
                        )
                    })}
                </div>
                
                <h2>
                    {firstBlock}
                </h2>
                <h3>Please read this website carefully, your legal rights may be affected.</h3>
                <p>{secondBlock}</p>
                <p>
                    This website was created to provide potential class members with more information about the case and to inform them of their rights. 
                    The case relates to the following products found on the <Link onClick={topLoad} to="/products">Products Page</Link>. Important documents from the case 
                    can be viewed on the <Link onClick={topLoad}  to="/documents">Documents Page</Link>.</p>
                <p>If you have questions or want more information, the contact information for Plaintiff's attorneys can be found <Link onClick={topLoad} to="/contact">here</Link> or fill out the form below.</p>   
            </div>
        </Body>
        
        <ContactForm />
        </>
    )
}


export default Home;