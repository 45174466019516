import imgSeal from '../components/images/seal.png';

export const HEADER_CONTENT = 
{
   seal: imgSeal,
   caseName: 'Brooks v. It Works Marketing, Inc., et al.,',
   caseNum: 'Case No: 1:21-cv-1341-DAD-JLT',
   district: 'UNITED STATES DISTRICT COURT EASTERN DISTRICT OF CALIFORNIA',
}


