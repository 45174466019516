import {PRODUCTS_CONTENT, PURCHASER_CONTENT} from '../../content/products-content.js';
import Body from '../layout/Body/Body';
import styles from './Products.module.css';
const Products = ()=>{
    const {company, classPeriod} = PURCHASER_CONTENT;
    return(
        <Body >
            <div className={styles.products}>
            <h1>Product Labels</h1>
            <h3>Purchasers of the following {company} products, purchased in the United States, {classPeriod}.</h3>
            <div className={styles.imgBlocks}>
                {PRODUCTS_CONTENT.map((img) =>{
                    return(
                        <div key={img.id}>
                            <img src={img.img} alt={img.alt} />
                            <p>{img.name}</p>
                        </div>   
                        )
                    })}
            </div>

            </div>
        </Body>
    )
}


export default Products;