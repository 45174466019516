import styles from './Contact.module.css';
import Body from '../layout/Body/Body';
import { CONTACT_CONTENT } from '../../content/contact-content';
const Contact = () =>{
    const {litigation} = CONTACT_CONTENT
    return(
        <Body>
            <div className={styles.contact}>
                <h1>Contact</h1>
                <p>If you require further information, please contact Class Counsel.</p>

                <div className={styles.info}>
                    <div>
                        <p className={styles.bold}>Phone:</p>
                        <p>619-798-2006</p>
                    </div>
                    <div>
                        <p className={styles.bold}>Email:</p>
                        <p>info@westonfirm.com</p>
                    </div>
                    <div>
                        <p className={styles.bold}>Mail:</p>
                        <div className={styles.address}>
                            <p>{litigation}</p>
                            <p>c/o The Weston Firm</p>
                            <p>1405 Morena Blvd., Suite 201</p>
                            <p>San Diego, CA 92110</p>
                        </div>
                    </div>
                </div>

                <p>
                    If you have any questions please contact The Weston Firm between 9am and 5pm Pacific Time at 619-798-2006, or contact your own attorney.
                </p>
                <p>
                    Please do not contact the Court regarding this Case. Neither the Court nor Defendant can give you legal advice regarding this case.
                </p>
            </div>
        </Body>
    )
}


export default Contact;