
import carb from '../components/images/carb.jpg';
import thermofight from '../components/images/thermofight.jpg';
import fat from '../components/images/fat.jpg';
import gummies from '../components/images/gummies.jpg';

export const PRODUCTS_CONTENT = [
    {
        id: 'img1',
        img: thermofight,
        alt: 'It Works! Class Action Thermofight Xˣ, a weight loss supplement',
        name: 'It Works! Thermofight Xˣ',
    },
    {
        id: 'img2',
        img: gummies,
        alt: 'It Works! Class Action Slimming Gummies, apple cider vingear weight loss gummies',
        name: 'It Works! Slimming Gummies',
    },
    {
        id: 'img3',
        img: carb,
        alt: 'It Works! Class Action Carb Control, a weight loss supplement',
        name: 'It Works! Carb Control',
    },
    {
        id: 'img4',
        img: fat,
        alt: 'It Works! Class Action Fat Fighter, a weight loss supplement',
        name:'It Works! Fat Fighter',
    },
]

export const PURCHASER_CONTENT = {
    company: 'It Works!',
    classPeriod: 'from September 1, 2017 to present',

}