import styles from './Header.module.css';
import { HEADER_CONTENT } from '../../../content/header-content';

const Header = () =>{
    const {seal, caseName, caseNum, district} = HEADER_CONTENT;
    return(
        <div className={styles.header}>
            <div>
                <img src={seal} alt=''/>
            </div>
            <div>
                <h1>{caseName}</h1>
                <h2>{caseNum}</h2>
                <h3>{district}</h3>
            </div>
        </div>
    )
}


export default Header;