import './App.css';
import {Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import Products from './pages/Products';
import Documents from './pages/Documents';
import Error from './pages/Error';
import Contact from './pages/Contact';
import NavBar from './layout/NavBar/NavBar';
import Header from './layout/Header/Header';


function App() {
  return (
    <>

        <NavBar />
        <Header />
        
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/products' element={<Products />} />
          <Route path='/*' element={<Error />} />
          <Route path='/documents' element={<Documents />} />
          <Route path='/contact' element={<Contact />} />
          
        </Routes>
    </>
  );
}

export default App;