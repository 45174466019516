import React, { Fragment, useRef, useState } from 'react';
import styles from './ContactForm.module.css';
import { useDispatch } from 'react-redux';
import { sendContactFormData } from '../../../store/contact-slice';
import useValidation from '../../../hooks/use-validation';



const ContactForm = (props) =>{
    const dispatch = useDispatch();
    const {line, form, contact, nameLabel, error, formInputs, nameInputs, nameError, success} = styles;
    const [submitClick, setSubmitClick] = useState(false);
    const [successfulSend, setsuccessfulSend] = useState(false);
    const {
        value: fName,
        error: fNameErr,
        isValid: fNameValid,
        handleBlur: fNameBlur,
        handleChange: fNameChange,
        reset: fNameReset
    } = useValidation((value) => value.trim() !== '');
    const {
        value: lName,
        error: lNameErr,
        isValid: lNameValid,
        handleBlur: lNameBlur,
        handleChange: lNameChange,
        reset: lNameReset
    } = useValidation((value) => value.trim() !== '');
    const {
        value: email,
        error: emailErr,
        isValid: emailValid,
        handleBlur: emailBlur,
        handleChange: emailChange,
        reset: emailReset
    } = useValidation((value) => value.trim() !== '');
    const {
        value: phone,
        error: phoneErr,
        isValid: phoneValid,
        handleBlur: phoneBlur,
        handleChange: phoneChange,
        reset: phoneReset
    } = useValidation((value) => value.trim() !== '');
    const {
        value: address,
        error: addressErr,
        isValid: addressValid,
        handleBlur: addressBlur,
        handleChange: addressChange,
        reset: addressReset
    } = useValidation((value) => value.trim() !== '');

    const {
        value: state,
        error: stateErr,
        isValid: stateValid,
        handleBlur: stateBlur,
        handleChange: stateChange,
        reset: stateReset
    } = useValidation((value) => value.trim() !== '');

    const {
        value: zip,
        error: zipErr,
        isValid: zipValid,
        handleBlur: zipBlur,
        handleChange: zipChange,
        reset: zipReset
    } = useValidation((value) => value.trim() !== '');

    const contactFormRef = useRef();


    const submitContactHandler = (event) =>{
        event.preventDefault();
        if(!fNameValid || !lNameValid || !emailValid || !phoneValid || !addressValid || !stateValid || !zipValid){
            setSubmitClick(true);
            return;
        }
        dispatch(sendContactFormData());
        setsuccessfulSend(true);
        
        fNameReset();
        lNameReset();
        emailReset();
        phoneReset();
        addressReset();
        stateReset();
        zipReset();

    }

    const fNameClass = fNameErr ? `${nameLabel} ${error}` : nameLabel;
    const lNameClass = lNameErr ? `${nameLabel} ${error}` : nameLabel;
    const fNameInputClass = fNameErr ? `${nameInputs} ${nameError}` : nameInputs;
    const lNameInputClass = lNameErr ? `${nameInputs} ${nameError}` : nameInputs;
    const emailClass = emailErr ? `${formInputs} ${error} ${line}` : `${formInputs} ${line}`;
    const phoneClass = phoneErr ? `${formInputs} ${error} ${line}` : `${formInputs} ${line}`;
    const addressClass = addressErr ? `${formInputs} ${error} ${line}` : `${formInputs} ${line}`;
    const stateClass = stateErr ? `${formInputs} ${error} ${line}` : `${formInputs} ${line}`;
    const zipClass = zipErr ? `${formInputs} ${error} ${line}` : `${formInputs} ${line}`;

    return(
        <Fragment>

        <div className={contact}>
            <h1>{props.title}</h1>
            {/* <p>{props.subTitle}</p> */}
            
            <form className={form} id="contact-form" ref={contactFormRef} onSubmit={submitContactHandler}>
            {submitClick && <p className={error}>Please fill out the entire form!</p>}
            {successfulSend && <p className={success}>Thank you, someone will contact you soon!</p>} 
                    <div>
                        <input 
                            name='form_name' 
                            type='hidden' 
                            value='It Works! Class Website' 
                            /> 
                    </div>
                    <div>
                        <label className={fNameClass} htmlFor='fName' >First Name</label>
                        <input
                            className={fNameInputClass} 
                            name='user_fName' 
                            type='text' 
                            value={fName} 
                            onChange={fNameChange}
                            onBlur={fNameBlur}
                            placeholder={props.fNamePlaceholder}/> 
                    </div>
                    <div >

                    <label className={lNameClass} htmlFor='lName'>Last Name</label>
                    <input 
                            className={lNameInputClass}
                            name='user_lName' 
                            type='text' 
                            value={lName} 
                            onChange={lNameChange}
                            onBlur={lNameBlur}
                            placeholder={props.lNamePlaceholder}/> 
                    </div>
       

                <div className={emailClass}>
                    <label htmlFor='email'>Email</label>
                    <input 
                        name='user_email' 
                        type='email' 
                        value={email} 
                        onChange={emailChange}
                        onBlur={emailBlur}
                        placeholder={props.emailPlaceholder}/> 
                </div>

                <div className={phoneClass}>
                    <label htmlFor='phone'>Phone</label>
                    <input 
                        name='user_phone' 
                        type='text' 
                        value={phone} 
                        onChange={phoneChange}
                        onBlur={phoneBlur}
                        placeholder={props.phonePlaceholder}/> 
                </div>

                <div className={addressClass}>
                    <label htmlFor='address'>Address</label>
                    <input 
                        name='user_address' 
                        type='text' 
                        value={address} 
                        onChange={addressChange}
                        onBlur={addressBlur}
                        placeholder={props.addressPlaceholder}/> 
                </div>

                <div className={stateClass}>
                <label htmlFor='address'>State</label>
                    <input 
                        name='user_state' 
                        type='text' 
                        value={state} 
                        onChange={stateChange}
                        onBlur={stateBlur}
                        placeholder={props.statePlaceHolder}/> 
                </div>

                <div className={zipClass}>
                <label htmlFor='address'>Zip/Postal Code</label>
                    <input 
                        name='user_zip' 
                        type='text' 
                        value={zip} 
                        onChange={zipChange}
                        onBlur={zipBlur}
                        placeholder={props.zipPlaceHolder}/> 
                </div>

                <div className={line}>
                    <button type='submit'>Sign Up</button>
                </div>   
            </form>
        </div>
        </Fragment>

    )
}

export default ContactForm;
