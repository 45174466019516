
import styles from './Error.module.css';

const Error = () =>{
    return(
        <div className={styles.error}>
            <h1>Error! Page Not Found.</h1>
            <h4>Sorry we cannot find the page you were looking for. Please try searching for something else. </h4>
        </div>

    )
}

export default Error;