
import { KEYDOCS_CONTENT, ADD_DOCS_CONTENT } from "../../content/documents-content";
import Body from "../layout/Body/Body";
import styles from './Documents.module.css';



const Documents = () =>{

    return(
        <Body>
            <h1>Documents</h1>
            <p>
                This page contains several key documents associated with this case.
                Click on the title of each document to open it as a Adobe PDF file.
            </p>
            <div className={styles.documents}>
                <h2>KEY DOCUMENTS</h2>
                <table className={styles.table}>
                    <thead>
                    <tr>
                    <th>DATE</th>
                    <th>DOCUMENT TITLE</th>
                    </tr>
                    </thead>
                    <tbody>
                    {KEYDOCS_CONTENT.map((doc) =>{
                        return(
                        <tr key={doc.id}>
                            <td>{doc.date}</td>
                            <td>
                                <a href={doc.link}>{doc.title}</a>
                            </td>
                        </tr>
                        )
                    }) }
                      </tbody>
                </table>
            </div>

            <div className={styles.documents}>
                <h2>ADDITIONAL DOCUMENTS</h2>
                <table className={styles.table}>
                <thead>
                    <tr>
                    <th>DATE</th>
                    <th>DOCUMENT TITLE</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ADD_DOCS_CONTENT.map((doc) =>{
                        return(
                        <tr key={doc.id}>
                            <td>{doc.date}</td>
                            <td>
                                <a href={doc.link}>{doc.title}</a>
                            </td>
                        </tr>
                        )
                    }) }
                    </tbody>
                </table>
            </div>
        </Body>
    )
}


export default Documents;