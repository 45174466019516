import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import store from './store';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize('G-0CR1GC20DW');

ReactDOM.render(
  <Provider store={store}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

