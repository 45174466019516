import key1 from './../documents/key/1.pdf'
import key2 from './../documents/key/2.pdf'
import key3 from './../documents/key/3.pdf'
import key4 from './../documents/key/4.pdf'
import key5 from './../documents/key/5.pdf'
import key6 from './../documents/key/6.pdf'
import key7 from './../documents/key/7.pdf'
import key8 from './../documents/key/8.pdf'
import key9 from './../documents/key/9.pdf'
import key10 from './../documents/key/10.pdf'
import key11 from './../documents/key/11.pdf'
import key12 from './../documents/key/12.pdf'
import key13 from './../documents/key/13.pdf'
import key14 from './../documents/key/14.pdf'
import key15 from './../documents/key/15.pdf'
import key16 from './../documents/key/order-denying-motion-to-compel-arbitration.pdf'
import key17 from './../documents/key/order-denying-preliminary-injunction.pdf'

import add1 from './../documents/add/consent_to_jurisdiction.pdf';
import add2 from './../documents/add/proof_of_service_complaint_mark_pentecost.pdf';
import add3 from './../documents/add/proof_of_service_complaint_itworks_marketing.pdf';
import add4 from './../documents/add/proof_of_service_complaint_itworks_global.pdf';
import add5 from './../documents/add/proof_of_service_complaint_paul_nassif.pdf';
import add6 from './../documents/add/initial_stipulation.pdf';
import add7 from './../documents/add/memorandum_motion_to_compel_arbitration.pdf';
import add8 from './../documents/add/declaration_tracy_zinsou_motion_to_compel.pdf';
import add9 from './../documents/add/exhibit_american_arbitration_association.pdf';
import add10 from './../documents/add/declaration_timothy_seat_motion_to_compel.pdf';
import add11 from './../documents/add/exhibit_itworks_terms_of_use.pdf';
import add12 from './../documents/add/exhibit_itworks_loyal_customer_agreement.pdf';
import add13 from './../documents/add/proposed_order_motion_to_compel_arbitration.pdf';
import add14 from './../documents/add/notice_of_exparte_application.pdf';
import add15 from './../documents/add/declaration_tracy_zinsou_exparte.pdf';
import add16 from './../documents/add/proposed_order_exparte_application.pdf';
import add17 from './../documents/add/opposition_to_motion_for_preliminary_injunction.pdf';
import add18 from './../documents/add/reply_support_motion_for_preliminary_injunction.pdf';

export const KEYDOCS_CONTENT = 
    [
        {
            id: 'key1',
            link: key1,
            date: 'September 3, 2021',
            title: 'Initial Class Action Complaint',
        },
        {
            id: 'key2',
            link: key2,
            date: 'September 3, 2021',
            title: 'Civil Cover Sheet',
        },
        {
            id: 'key3',
            link: key3,
            date: 'September 8, 2021',
            title: 'Summons',
        },

        {
            id: 'key4',
            link: key4,
            date: 'October 27, 2021',
            title: "Defendants' Memorandum of Law in Support of Motion to Compel Arbitration",
        },
        {
            id: 'key5',
            link: key5,
            date: 'November 8, 2021',
            title: 'First Amended Class Action Complaint',
        },
        {
            id: 'key6',
            link: key6,
            date: 'November 23, 2021',
            title: "Plaintiff's Opposition to Defendants' Motion to Compel Arbitration",
        },
        {
            id: 'key7',
            link: key7,
            date: 'November 23, 2021',
            title: "Declaration of Aileen Brooks In Support of Plaintiff's Opposition to Motion to Compel Arbitration",
        },
        {
            id: 'key8',
            link: key8,
            date: 'Novemeber 23, 2021',
            title: "Declaration of Gregory S. Weston In Support of Plaintiff's Opposition to Motion to Compel Arbitration",
        },
        {
            id: 'key9',
            link: key9,
            date: 'November 30, 2021',
            title: "Defendants' Reply In Support of Motion to Compel Arbitration",
        },
        {
            id: 'key10',
            link: key10,
            date: 'December 21, 2021',
            title: "Plaintiff's Notice of Motion and Motion for Preliminary Injunction and Provisional Class Certification",
        },
        {
            id: 'key11',
            link: key11,
            date: 'December 21, 2021',
            title: "Plaintiff's Memorandum In Support of Motion for Preliminary Injunction and Provisional Class Certification",
        },
        {
            id: 'key12',
            link: key12,
            date: 'December 21, 2021',
            title: "Declaration of Gregory S. Weston In Support of Plaintiff's Motion fo Preliminary Injunction",
        },
        {
            id: 'key13',
            link: key13,
            date: 'December 21, 2021',
            title: "Declaration of Nathan Wong Re: Plaintiff's Motion for Preliminary Injunction",
        },
        {
            id: 'key14',
            link: key14,
            date: 'December 21, 2021',
            title: "Declaration of William M. London Re: Plaintiff's Motion for Preliminary Injunction",
        },
        {
            id: 'key15',
            link: key15,
            date: 'December 21, 2021',
            title: "Declaration of Robert L. FitzPatrick Re: Plaintiff's Motion for Preliminary Injunction",
        },
        {
            key: 'key16',
            link: key16,
            date: 'June 9, 2022',
            title: "Order Denying Defendants' Motion to Compel Arbitration",
        },
        {
            key: 'key17',
            link: key17,
            date: 'June 21, 2022',
            title: "Order Denying Plaintiff's Motion for a Preliminary Injunction & Provisional Class Certification",
        },
        
    ]
    
    

    export const ADD_DOCS_CONTENT = 
    [
        {
            id: 'add1',
            link: add1,
            date: 'September 8, 2021',
            title: "Consent of U.S. Magistrate Judge Jurisdiction",
        },
        {
            id: 'add2',
            link: add2,
            date: 'September 8, 2021',
            title: "Proof of Service of Summons and Complaint to Mark Pentecost",
        },
        {
            id: 'add3',
            link: add3,
            date: 'September 14, 2021',
            title: "Proof of Service of Summons and Complaint to It Works Marketing, Inc.",
        },
        {
            id: 'add4',
            link: add4,
            date: 'September 14, 2021',
            title: "Proof of Service of Summons and Complaint to It Works! Global, Inc.",
        },
        {
            id: 'add5',
            link: add5,
            date: 'September 16, 2021',
            title: "Proof of Service of Summons and Complaint to Dr. Paul Nassif",
        },
        {
            id: 'add6',
            link: add6,
            date: 'September 28, 2021',
            title: "Initial Stipulation to Extend Time for Defendants to Answer or Otherwise Respond to Complaint",
        },
        {
            id: 'add7',
            link: add7,
            date: 'October 27, 2021',
            title: "Defendants' Memorandum of Law In Support of Motion to Compel Arbitration",
        },
        {
            id: 'add8',
            link: add8,
            date: 'October 27, 2021',
            title: "Declaration of Tracy O. Zinsou In Support of Defendants' Motion to Compel Arbitration",
        },
        {
            id: 'add9',
            link: add9,
            date: 'October 27, 2021',
            title: "Exhibit One to Zinsou Declaration",
        },
        {
            id: 'add10',
            link: add10,
            date: 'October 27, 2021',
            title: "Declaration of Timothy Seat In Support of Defendants' Motion to Compel Arbitration",
        },
        {
            id: 'add11',
            link: add11,
            date: 'October 27, 2021',
            title: "Exhibit One to Seat Declaration",
        },
        {
            id: 'add12',
            link: add12,
            date: 'October 27, 2021',
            title: "Exhibit Two to Seat Declaration",
        },
        {
            id: 'add13',
            link: add13,
            date: 'October 27, 2021',
            title: "[Proposed] Order Granting Defendants' Motion to Compel Arbitration",
        },
        {
            id: 'add14',
            link: add14,
            date: 'December 28, 2021',
            title: "Notice of Ex Parte Application and Ex Parte to Continue Hearing on Plaintiff's Motion for Preliminary Injuction and Provisional Class Certification",
        },
        {
            id: 'add15',
            link: add15,
            date: 'December 28, 2021',
            title: "Declaration of Tracy O. Zinsou In Support of Defendants' Ex Parte Application",
        },
        {
            id: 'add16',
            link: add16,
            date: 'December 28, 2021',
            title: "[Proposed] Order Granting Defendants' Ex Parte Application",
        },
        {
            id: 'add17',
            link: add17,
            date: 'January 18, 2022',
            title: "Defendants' Opposition to Plaintiff's Motion for Preliminary Injunction & Provisional Class Certification",
        },
        {
            id: 'add18',
            link: add18,
            date: 'January 25, 2022',
            title: "Plaintiff's Reply in Support of Motion for Preliminary Injunction & Provisional Class Certification",
        },
    ]