import { useState } from "react";
import { NavLink } from "react-router-dom"
import {FaBars} from 'react-icons/fa';
import styles from './NavBar.module.css'

const NavBar = () =>{
    const {navBar, active, mobileMenu, navOptions} = styles;
    const [showNav, setShowNav] = useState(false);

    const handleMenuClick = () => setShowNav(!showNav);

    const topLoad = () =>{
        window.scrollTo(0, 0);
    }

    return(
        <header className={navBar}>
            
                <div className={showNav ? `${navOptions} ${active}`: navOptions}>
         
                <NavLink onClick={topLoad} className={(navData) => navData.isActive ? active : ''} to='/'>
                    HOME
                </NavLink>
                <NavLink onClick={topLoad}  className={(navData) => navData.isActive ? active : ''} to='/products'>
                    PRODUCTS
                </NavLink>
                <NavLink onClick={topLoad}  className={(navData) => navData.isActive ? active : ''} to='/documents'>
                    DOCUMENTS
                </NavLink>
                <NavLink onClick={topLoad}  className={(navData) => navData.isActive ? active : ''} to='/contact'>
                    CONTACT
                </NavLink>
                </div>
                <div className={mobileMenu}>
                {!showNav ? <FaBars onClick={handleMenuClick} /> : <FaBars onClick={handleMenuClick} /> }
                </div>
            
            
        </header>
    )
}

export default NavBar;
